import {API, Cache} from 'aws-amplify';
import { clickEvents, alertUser, makeErrorMessage, bytesConversion, isfolder, handleEvals, oneOffClicks } from './tools';
import $ from "jquery";
import { Datepicker } from 'vanillajs-datepicker';
import 'vanillajs-datepicker/dist/css/datepicker-bs5.css';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-select-bs5'
import "datatables.net-select-bs5/css/select.bootstrap5.min.css";
import 'datatables.net-responsive-bs5/'
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import AWS from 'aws-sdk';
import { init } from "../app";
import { Modal, Offcanvas } from 'bootstrap';

var currentPath = "templates/";
var currentFolder = "default";
var explorerTable = null;
var company_name = null;
var sheetloomTier = null;

var tagsSide = new Offcanvas("#tagsOffcanvas");

(async() => 
{
    let args = await init();
    company_name = args.company_name;
    sheetloomTier = args.tier;
    console.log("COMPANY NAME: " + company_name);
    console.log("SHEETLOOM TIER: " + sheetloomTier);

    await createTable();
    
    // warm up patterns DB
    getRequest(true);

    handleEvals(document.getElementsByTagName("body")[0].getAttribute('data-nonce'));
    
    await getTemplates(company_name);

    explorerClickEvents();

    oneOffClicks("explorer");
    
    // check for url params to auto weave
    console.debug("checking url parameters...");
    if (window.location.href.includes('?')) {
        // get the url params
        var query = window.location.href.split("?")[1];
        console.debug("url query: ", query)
        var result = {};
        query.split("&").forEach(function(part) {
            var item = part.split("=");
            console.debug("item: ", item)
            result[item[0]] = decodeURIComponent(item[1]);
        });
        let urlParams = result;
        // only if 'path' is set to a template
        let urlPatternPath = null;
        if (urlParams.hasOwnProperty("path")){
            urlPatternPath = urlParams.path
            delete urlParams.path
            if (urlParams.hasOwnProperty("access_token")){
                delete urlParams.access_token
            }
            if (urlParams.hasOwnProperty("expires_in")){
                delete urlParams.expires_in
            }
            if (urlParams.hasOwnProperty("token_type")){
                delete urlParams.token_type
            }
            if (urlParams.hasOwnProperty("id_token")) {
                delete urlParams.id_token
            }
            console.debug("urlPatternPath: " + urlPatternPath)
            console.debug("urlParams: " + JSON.stringify(urlParams))
        }
        if(urlPatternPath.split('.').pop() == 'xlsx'){
            callSheetloom(urlPatternPath, urlParams);
        }
    }

    $.fn.dataTable.tables( { visible: true, api: true } ).columns.adjust();

})();

//////////////////////////////////////////////////////////
//  delete button
//////////////////////////////////////////////////////////
$('#explorerDeleteButton').on("click", async function () {
    let data = explorerTable.rows( { selected: true } ).data();
    console.log(data);
    let le_selected = [];
    for (let index = 0; index < data.length; index++) {
        const le_name = $(data[index][1]).text();
        le_selected.push(le_name);
    };
    $(".confirm-delete-modal-text").text("template")
    $(".confirm-delete-modal p.text-center").text("Do you want to delete template(s): " + le_selected.join(", "));    
    Modal.getOrCreateInstance('#confirm-delete-modal').toggle();
});


//////////////////////////////////////////////////////////
//  weave button
//////////////////////////////////////////////////////////
$('#explorerWeaveButton').on("click", async function () {
    if(explorerTable.rows(".selected").nodes().length == 1){
        let data = explorerTable.rows( { selected: true } ).data();
        let id = $(data[0][0]).attr("id");
        console.debug(id);
        callSheetloom(id);
    }
});

// delete triggered by the delete modal
$(".confirm-delete-btn").on("click", async function() {    
    console.debug(explorerTable.rows( { selected: true } ).data());
    let data = explorerTable.rows( { selected: true } ).data();
    console.log(data.length);
    for (let index = 0; index < data.length; index++) {
        const id = $(data[index][0]).attr("id");
        console.debug(id);
        await deleteFile(id).then(result => {
            console.log(result);
          })
          .catch(error => {
            console.error(error);
          });
    };
    Modal.getOrCreateInstance('#confirm-delete-modal').toggle();
    await getTemplates(company_name);
    if (!($("#explorerDeleteButton").hasClass("hidden"))) {
        $("#explorerDeleteButton").addClass("hidden");
    }
});

//////////////////////////////////////////////////////////

async function getRequest(hideError) {
    const leInit = {
        headers: headers,
        response: false,
        queryStringParameters: {
          id: '0'
        }
    };
    console.debug("headers: " , headers);
    await API.get('patterns', '/patterns', leInit)
    .then(() => {
        console.debug("patterns db primed");
    })
    .catch((error) => {
        if(!hideError){
            console.debug("error getting data:");
            console.debug("error: "+error.message);
            makeErrorMessage(error);
            // show popup
            alertUser(error);
        }
    });
}

export async function getTemplates(company_name) {
    $('.foldercard').remove(); 
    explorerTable.clear();
    $('#loading-modal').removeClass('hidden');
    const leInit = {
        headers: headers,
        response: false,
        body: {"id_token": Cache.getItem('idToken'), "company_name": company_name}
    };
    console.debug("headers: " , headers);
    await API.post('core', '/templates', leInit)
    .then(async (response) => {
        console.log("before: " + response)
        let templates = response
        console.log("templates: " + templates)
        console.log(Object.keys(templates))
        Object.keys(templates).forEach(function(item, index){
            if(item == currentFolder) {
                $('#addfolder').before('<div title="' + item + '" class="col-2 foldercard"><div class="card curFolder"><div class="card-body"><div class="folderImgDiv" style="float:left"><img src="assets/folderIcon.svg"></div><div class="folderName" id="curFolderCard">' + item + '</div></div></div></div>') 
            }else{
                $('#addfolder').before('<div title="' + item + '" class="col-2 foldercard"><div class="card"><div class="card-body"><div class="folderImgDiv" style="float:left"><img src="assets/folderIcon.svg"></div><div class="folderName" id="folderCard">' + item + '</div></div></div></div>') 
            }
        });
        let tempCount = 0;
        console.log(templates[currentFolder])
        templates[currentFolder].forEach(function(item, index) {
            console.log("Add template")
            explorerTable.row.add(['<div id="' + item.key + '"/>','<span class="expandRowClass" role="button">'+ item.key +'</span>', item.lastModified, bytesConversion(item.size), '<div class="px-3 py-1 weave-btn" title="Weave"><img src="assets/play.svg" title="Weave" alt="Weave"></div>', '']);
            tempCount++;
        });
        $('#loading-modal').addClass('hidden');
        explorerTable.draw();        
    })
    .catch((error) => {
        $('#loading-modal').addClass('hidden');
        console.error(error)
        makeErrorMessage("Templates could not be loaded at this time. Please try again later.");
    });
}

function clickfunc(e) {
    currentFolder = $(e).text();
    getTemplates(company_name);
}

function paramModalWeave() {
    let count = 0;
    let params = {};
    while(true) {
        console.log($('#param' + count + 'In').val())
        if(document.getElementById('param' + count + 'In') != null) {
            let paramName = $('#param' + count + 'Label').html();
            let paramVal = $('#param' + count + 'In').val();
            params[paramName] = paramVal;
            count++;
        } else {
            break;
        }
    }
    let tempTemplate = Cache.getItem('tempTemplate')
    alertUser(tempTemplate + ": Weaving");
    console.log("PARAMS FROM MODAL: " + JSON.stringify(params))
    const leInit = {
        headers: headers,
        response: false,
        queryStringParameters: {
          domain: Cache.getItem('lh'),
          template: tempTemplate
        },
        body: params
    };
    console.debug("headers: " , headers);
    API.post('core', '/', leInit)
    .then(() => {
        console.debug("Weaving Initiated");
    })
    .catch((error) => {
    console.error(error);
    });
}

function expandVersionRow(e, refresh) {
    console.debug(e);
    var explorerTable = $('#explorerTable').DataTable();
    let s3 = new AWS.S3(AWS.config);
    let templatePath = currentFolder + '/templates/' + e.html();
    var tr = $(e).closest('tr');
    console.debug(tr);
    var row = explorerTable.row(tr);
    
    if (row.child.isShown() && !refresh) {
        $('div.slider', row.child()).slideUp( function () {
            row.child.hide();
            tr.removeClass('shown');
        } );  
    } else {
        let versions = ''
        let tags = ''
        
        s3.listObjectVersions({Bucket: Cache.getItem('sheetloom_bucket'), Prefix: templatePath}, function(err, data) {
            if (err) {
                console.log(err); 
            } else {
                versions = data
                console.log(versions);
                
                s3.getObjectTagging({Bucket: Cache.getItem('sheetloom_bucket'), Key: templatePath}, function(err, data) {
                    if (err) {
                        console.log(err); 
                    } else {
                        tags = data
                        console.log(tags);
                        let leRow = expandVersionRowCb(templatePath, versions, tags, row);
                        row.child(leRow, 'no-padding').show();
                        tr.addClass('shown');
                        $('div.slider', row.child()).slideDown();
                    }
                });
            }
        });
    }
}

function expandVersionRowCb(key, versions, tags, row) {
    console.log("Showing " , key)        
    console.log("versions: " , versions)        
    console.log("tags: " , tags)        
    // row.child(subRowFormat(key, versions, tags), 'hideMe no-padding').show();
    return subRowFormat(key, versions, tags);
}

// add tr for each tag/version
function subRowFormat(key, versions, tags) {
    let slider = `<div class="slider">
    <div class="container-fluid">
    <div class="row">
    <div class="col-sm-4">
        <h5 style="align-items: center;" class="d-inline-flex">Tags  
            <div class="px-2 py-1 edit-tags-btn ms-2" title="Edit Tags" id="` + key + `">
                <img src="assets/edit.svg" title="Edit Tags" alt="Edit Tags">
            </div>   
        <h5> 
    <table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">
    <tr> 
        <td> <strong>Key</strong> </td> 
        <td> <strong>Value</strong> </td> 
    </tr>`;
    
    tags.TagSet.forEach((e) => {
        slider += '<tr>' +
        '<td> ' + e.Key.toString() + '</td>' +
        '<td> ' + e.Value.toString() + '</td>' +
        '</tr>'
    });
    
    slider += '</table></div>'
    slider += '<div class="col-sm-8"><h5>Past Versions<h5><table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">';
    let count = 0;
    versions.Versions.forEach((e) => {
        if(count > 9) return;
        console.log(e);
        const formattedDate = e.LastModified.toUTCString().toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }).replace(",","");
        console.log(formattedDate);
        slider += '<tr>' +
        // '<td>' + e.LastModified.toString().split("GMT")[0] + '</td>' +
        '<td>' + formattedDate + '</td>' +
        '<td>' + "-" + '</td>' + 
        '<td>' + bytesConversion(e.Size) + '</td>' +
        '<td> <div class="px-2 py-1 download-btn ms-2" title="Download" id="'+e.Key+'||'+ e.VersionId + '"><img src="assets/download.svg" title="Download" alt="Download"></div> </td>' +
        '</tr>'
        count++
    });
    
    slider += '</table></div></div></div></div>';
    //console.log(slider);
    return slider;
}




function editTags(key){
    let s3 = new AWS.S3(AWS.config);
    console.log("editing " + key + " tags...")
    $('#tag-edit-form-identifiers').html(key);
    $('#tag-edit-form-tags').empty()
    s3.getObjectTagging({Bucket: Cache.getItem('sheetloom_bucket'), Key: key}, function(err, data) {
        if (err) {
            console.log(err); 
        } else {
            let tags = data;
            console.log(tags);
            
            // fill out form
            let count = 1
            tags.TagSet.forEach((e) => {                
                let tagHTML = ' <div id="taggy'+count+'" class="form-group tag-form-group"> \
                <div class="mb-1"> \
                <a class="removeTagRow btn btn-default _jsonform-array-deletelast" title="Remove tag" id="tag' + count + '"> \
                <i class="glyphicon glyphicon-minus-sign" title="Remove row"> \
                </i> \
                </a> \
                <label class="h6 tag-label">Tag '+count+'</label> \
                </div> \
                <div class="ps-3 form-group"> \
                <label for="key-field">Key <em>*</em></label> \
                <div class="controls"> \
                <input type="text" class="form-control key-field" name="key" value="'+e.Key.toString()+'" id="key-field" aria-label="Key" required="required"> \
                </div> \
                </div> \
                <div class="ps-3 form-group"> \
                <label for="value-field">Value</label> \
                <div class="controls"> \
                <input type="text" class="form-control value-field" name="value" value="'+e.Value.toString()+'" id="value-field" aria-label="Value"> \
                </div> \
                </div> \
                </div> '
                
                $('#tag-edit-form-tags').append(tagHTML)
                count++
            });
            
            if(tags.TagSet.length==0){
                addTagRow();
            }
            
            tagsSide.show();
        }
    });
}




function addTagRow(){
    console.log("adding tag")
    // get number of tags
    let next = $('.tag-form-group').length + 1
    
    let tagHTML = ' <div id="taggy'+next+'" class="form-group tag-form-group"> \
    <div class="mb-1"> \
    <a class="removeTagRow btn btn-default _jsonform-array-deletelast" id="tag'+next+'" title="Remove tag"> \
    <i class="glyphicon glyphicon-minus-sign" title="Remove row"> \
    </i> \
    </a> \
    <label class="h6 tag-label">Tag '+next+'</label> \
    </div> \
    <div class="ps-3 form-group"> \
    <label for="key-field">Key <em>*</em></label> \
    <div class="controls"> \
    <input type="text" class="form-control key-field" name="key" value="" id="key-field" aria-label="Key" required="required"> \
    </div> \
    </div> \
    <div class="ps-3 form-group"> \
    <label for="value-field">Value</label> \
    <div class="controls"> \
    <input type="text" class="form-control value-field" name="value" value="" id="value-field" aria-label="Value"> \
    </div> \
    </div> \
    </div> '
    
    
    $('#tag-edit-form-tags').append(tagHTML);
}

async function removeTagRow(tag){  // tag = 'tag2'
    tag = tag.replace("tag", "taggy");
    console.log("removing tag: " + tag);
    let currrent = tag.substring(3);
    console.log(currrent);
    $('#'+tag).remove();
    let total = $('.tag-form-group').length;
    console.log(total);
    
    // sort index below removed
    for (let i = currrent; i <= total; i++) {
        const tagId = '#tag'+ (parseInt(i)+1);
        const previousTagId = 'tag'+ (parseInt(i));
        console.log(tagId)
        console.log(previousTagId)
        $(tagId + ' .tag-label').html("Tag " + i)
        $(tagId).attr("id", previousTagId);
    }
    
}

function putTemplateTags(){
    let s3 = new AWS.S3(AWS.config);
    let key = $('#tag-edit-form-identifiers').html()
    let values = $("#tag-edit-form").serializeArray()
    console.log('key: ', key);
    console.log('values: ', values);
    
    var tagParams = {
        Bucket: Cache.getItem('sheetloom_bucket'), 
        Key: key, 
        Tagging: {
            TagSet: []
        }
    };
    
    // format values for request
    for (let i = 0; i < values.length; i++) {
        let tagKey = values[i].value
        i++
        let tagValue = values[i].value
        let tag = {"Key": tagKey, "Value": tagValue}
        console.log('tag: ', tag)
        tagParams.Tagging.TagSet.push(tag)
    }
    console.log(tagParams)
    
    //update template tags
    s3.putObjectTagging(tagParams, function(err, data) {
        if (err) {
            console.log(err, err.stack); 
            alertUser("Error updating tags for " + key)
        }
        else{
            console.log(data);  
            console.log(key + " tags successfully updated.")
            alertUser(key + " tags successfully updated.")
            
            // refresh expanded row
            // key = "default/templates/csvtest18.xlsx"/////////
            key = key.split('/').pop()
            // key = "span#" + key.replaceAll('.','\\.')
            // let el = $((key))
            let el = $('<span>', {
                'role': 'button',
                'id': key,
                'text': key,
                'click': function() {
                    expandVersionRow($(this), true);
                }
            });
            // $(el).click()
            // expandVersionRow(el, true)
            getTemplates(company_name)

            tagsSide.hide();

            $('#tag-edit-form-identifiers').empty()
            $('#tag-edit-form-tags').empty()
        }
    });
}


function downloadFile(filePath, vid, dateString) {
    let s3 = new AWS.S3(AWS.config);
    let fileName = filePath.split("/")[filePath.split("/").length - 1];

    // Parse the input date string
    let dateParts = dateString.split(/[\/ :]/); // Split by '/', ' ', and ':'
    let year = dateParts[2];
    let month = dateParts[0];
    let day = dateParts[1];
    let time = dateParts.slice(3).join('');

    // Format the date in the desired way
    let formattedDate = year + month + day + '_' + time;
    console.log(formattedDate);
    
    let url = "";
    if(fileName.includes(".xlsx")) {
        url = s3.getSignedUrl('getObject', {
            Bucket: Cache.getItem('sheetloom_bucket'),
            Key: filePath,
            VersionId: vid,
            Expires: 60 * 5,
            ResponseContentDisposition: 'attachment; filename ="' + fileName.split(".xlsx")[0] + '_template_' + formattedDate + '.xlsx"'
        });
    } else {
        url = s3.getSignedUrl('getObject', {
            Bucket: Cache.getItem('sheetloom_bucket'),
            Key: filePath,
            VersionId: vid,
            Expires: 60 * 5
        });
    }
    console.log(url);
    window.open(url, '_blank');
    return url;
}


function deleteFile(templatePath) {
    return new Promise((resolve) => {
        let s3 = new AWS.S3(AWS.config);
        
        var params = {  Bucket: Cache.getItem('sheetloom_bucket'), Key: currentFolder + '/templates/' + templatePath };
        console.log("Template Path: " + templatePath);
        console.log(params);
        s3.deleteObject(params, async function(err, data) {
            if (err) {
                console.log(err, err.stack);  // error
            } else {  
                console.log("File deleted: " + templatePath);  //deleted
                alertUser((isfolder(templatePath) ? "Folder" : "File") + " deleted: " + templatePath);
                delete params.Key;
                params.Prefix = currentPath;
            }              
        });
        setTimeout(() => {resolve("Deleted File");}, 500);
    });
}

/////////////////////
// tag form
/////////////////////
var tagSchema = {
    "tags": {
        "title": "Name <em>*</em>",
        "description": "",
        "type": "string",
        "required": true
    }
};

var tagForm = [
    {
        "key": "tags",
        "type": "text",
        "fieldHtmlClass": "tag-name-field",
        "htmlClass": "tag-name"
    }
]

function callSheetloom(temp, urlParams = null) {
    let template = company_name + "-sheetloom-templates/" + currentFolder + "/templates/" + temp;
    let lh = Cache.getItem('lh');
    console.log("TEMPLATE: " + template);  // eg: "templates/CoolCargo.xlsx"
    alertUser(temp + ": Checking");
    const leInit = {
        headers: headers,
        response: false,
        queryStringParameters: {
          domain: lh,
          template: template
        }
    };
    API.get('core', '/parameters', leInit)
    .then(async (response) => {
        console.log("Parameters response: " + JSON.stringify(response));
        let templateName = template.substring(template.lastIndexOf("/")+1, template.length).replace(".xlsx", "");
        console.log("Template Name: " + templateName);
        console.log("TEMPLATE NAME: " + JSON.stringify(response[templateName]));
        if(JSON.stringify(response[templateName]) == "{}") {
            alertUser(temp + ": Weaving");
            await API.get('core', '/', {headers: headers, response: false, queryStringParameters: {domain: lh, template: template}})
            .then(() => {})
            .catch((error) => {
            console.error(error);
            });
        } 
        else {
            //open modal for params to be entered
            console.log(Object.keys(response[templateName]));
            console.log(urlParams)           
            if(urlParams != null) {
                alertUser(temp + ": Weaving");
                await API.post('core', '/', {headers: headers, response: false, queryStringParameters: {domain: lh, template: template}, body: urlParams})
                .then(() => {})
                .catch((error) => {
                console.error(error);
                });
            } else {
            let params = Object.keys(response[templateName]);
            $('#paramModalBody').empty();
            $('#paramModalBody').append('<form>')
            Cache.setItem("tempTemplate", template);
            params.forEach(el => {   
                let param = response[templateName][el]
                $('#paramModalBody').append('<div class="form-group">' +
                '<label id="param' + el + 'Label" for="param' + el + 'In">' + param.name + '</label> </br>' +

                '<input id="param' + el + 'In" type="text" value="' + param.default + '" name="paramValue"> </br>' +

                '<small id="param' + el + 'Help" class="form-text text-muted">' + param.description + '</small>' +
                '</div>')

                if (param.type=="date") {
                    const elem = document.querySelector('input[name="paramValue"]');
                    const datepicker = new Datepicker(elem, {
                        buttonClass: 'btn',
                        format: 'yyyy-mm-dd'
                    }); 
                }
            })
            Modal.getOrCreateInstance('#paramModal').toggle();
        }
    }
    })
    .catch((error) => {
        console.error(error);
    });    
}

$("#param-modal-weave-btn").on("click", function (event) {
    Modal.getOrCreateInstance('#paramModal').toggle();
    paramModalWeave();
});

function addUploadCheckboxIfPlus(){
    $('#uploadModalBody').empty();
    // Create a file input element
    //var fileInput = $('<input class="form-control" type="file" id="fileUpload" required="required">');
    // Create a label element
    //var label = $('<label class="input-group-text" aria-label="Choose File" for="fileUpload">Choose File</label>');
    if (sheetloomTier != 0) {
        // Create a checkbox element
        var checkbox = $('<input type="checkbox" class="me-2" id="checkBox" name="checkBox">');
        // Create a label for the checkbox
        var checkboxLabel = $('<label for="checkBox" class="mt-3 me-2">Daily automatic refresh? </label>');
    }
    var customFileUploadDiv = $('<div class="input-group custom-file-button mb-3 mt-3"><label class="input-group-text" aria-label="Choose File" for="fileUpload">Choose File</label><input class="form-control" type="file" id="fileUpload" required="required"></div>')

    // Append the file input, label, checkbox, and checkbox label to the paramModalBody
    $('#customFileUploadDiv')
    $('#uploadModalBody').append(customFileUploadDiv, checkboxLabel, checkbox);
}

$("#templateUploadFormBtn").on("click", function (event) {
    addUploadCheckboxIfPlus();
    Modal.getOrCreateInstance('#templateUploadModal').toggle();
});




async function addTagToS3Object(bucket, key, tagKey, tagValue) {
    try {
        // Create a tagging configuration
        const taggingConfig = {
            TagSet: 
            [{
                Key: tagKey,
                Value: tagValue
            }]
        };

        // Add tags to the S3 object
        await new AWS.S3(AWS.config).putObjectTagging({
            Bucket: bucket,
            Key: key,
            Tagging: taggingConfig
        }).promise();

        console.log(`Tag added to S3 object: ${key}`);
    } catch (error) {
        console.error('Error adding tag:', error);
    }
}


$("#template-modal-upload-btn").on('click',function(){
    console.log("in upload")
    var s3 = new AWS.S3(AWS.config);
    var files = document.getElementById('fileUpload').files;
    if (files) 
    {
        console.log("files found")
        var file = files[0];
        var fileName = file.name;
        var filePath = currentFolder + '/templates/' + fileName;
        alertUser("Uploading file: " + fileName);
        upload_response = s3.upload({
            Key: filePath,
            Body: file,
            Bucket: Cache.getItem('sheetloom_bucket')
        }, async function(err, data) {
            if(err) {
                console.error(err)
                makeErrorMessage(err)
            }
            else{
                Modal.getOrCreateInstance('#templateUploadModal').toggle();
                console.log("Upload success: ", data)
                $('#explorerTable').DataTable().clear();
                $('#loading-modal').removeClass('hidden');
                $('.foldercard').remove();
                alertUser("File finished uploading: " + fileName);
                document.getElementById('fileUpload').value = null;

                if (sheetloomTier != 0) {
                    const checkbox = document.getElementById('checkBox');
                    await addTagToS3Object(Cache.getItem('sheetloom_bucket'), filePath, "refresh", (checkbox.checked).toString());
                }

                await getTemplates(company_name);
            }
        });
    }
});



async function createTable() {
    explorerTable = $('#explorerTable').DataTable( {
        paging: true,
        destroy: true,
        select: true,
        responsive: {
            details: false
        },
        columns: [
            { title: '<i id="select_all"></i>' },
            { title: "Name" },
            { title: "Last Modified" },
            { title: "Size" },
            { title: "" },
            {
                "className":      'options',
                "data": null,
                "render": function(data, type, full, meta){
                    // console.debug(data);
                    return `
                    <div class="dropdown dropleft">
                        <button class="btn btn-default" type="button" id="menu' + tempCount +'" data-bs-toggle="dropdown" style="float:right">
                            <img src="assets/3dotmenu.svg">
                        </button>
                        <ul class="dropdown-menu" role="menu" aria-labelledby="menu' + tempCount + '">
                            <li role="presentation">   
                                <a role="menuitem" class="ddBtnExpand" style="padding: 5px;"> Show Details </a>   
                                <hr>    
                                <a role="menuitem" class="ddBtnDelete users-hide" style="padding: 5px;"> Delete </a>    
                            </li> 
                        </ul>
                    </div>
                    `
                }
            }
        ],
        columnDefs: [ 
            { 
                responsivePriority: 6,
                orderable: false,
                className: 'select-checkbox',
                targets: 0 
            },
            { responsivePriority: 1, orderable: true, searchable: true, className: 'explorer-name-column', targets: 1 },
            { responsivePriority: 3, orderable: true, searchable: true, className: 'explorer-modified-column', targets: 2 },
            { responsivePriority: 4, orderable: true, searchable: true, className: 'explorer-size-column', targets: 3 },
            { responsivePriority: 2, orderable: false, searchable: false, className: 'explorer-weave-column', targets: 4 },
            { responsivePriority: 5, orderable: false, searchable: false, className: 'explorer-menu-column', targets: 5 }
        ]
    } );
}

function explorerClickEvents() {
    $("body").on("click", ".card-body", function(event) {
        console.log(company_name);
        currentFolder = $(this).text();
        getTemplates(company_name);
    });

    $("body").on("click", ".expandRowClass", function(event) {
        event.stopPropagation();
        expandVersionRow($(this));
    });

    $("body").on("click", ".weave-btn", function(event) {
        event.stopPropagation();
        let id = $(this).closest('tr').find('.select-checkbox div').attr("id");
        console.log(id);
        callSheetloom(id);
    });

    $("body").on("click", ".ddBtnExpand", function(event) {
        expandVersionRow(  $(this).closest('tr').find('.explorer-name-column span'));
    });

    $("body").on("click", ".ddBtnDelete", function(event) {
        $(".confirm-delete-modal p.text-center").text("Do you want to delete this file?");
        Modal.getOrCreateInstance('#confirm-delete-modal').toggle();
    });

    $("#tableRow").on("dblclick", "#explorerTable tbody tr", function(event) {
        expandVersionRow(  $(this).find('.explorer-name-column span'));
    });

    $("body").on("click", "#backbutton", async function(event) { 
        clickfunc(this);   
    });

    $("body").on("click", ".edit-tags-btn", function(event) {
        let id = $(this).attr("id");
        console.log(id);
        editTags(id);
    });

    $("body").on("click", "#addTagRow", function(event) {
        addTagRow();
    });

    $("body").on("click", "#tagsEditSubmitButton", function(event) {
        putTemplateTags();
    });

    $("body").on("click", ".download-btn", function(event) {
        let data = $(this).attr("id");
        console.log(data);
        let id = data.split("||")[0];
        let vid = data.split("||")[1];
        console.log(id);
        console.log(vid);
        let timestamp = $(this).closest('tr').find('td:first').text();
        console.log(timestamp);
        downloadFile(id, vid, timestamp);
    });

    $("body").on("click", ".removeTagRow", function(event) {
        removeTagRow($(this).attr('id'));
    });

    clickEvents("explorerTable", "explorer", explorerTable);
}
